import Vue from 'vue'
import Element from 'element-ui'
import App from './App.vue'
import router from '@/router/routers'
import '@/assets/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Element, { size: 'medium' })

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App),
})
