<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
.el-popover{
  max-height: 600px;
  padding-right: 0;
  overflow: auto;
}
body .el-table th.gutter {
  display: table-cell !important;
}
::-webkit-scrollbar {
  display: none;
}
</style>

