import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    meta: { title: '主页', noCache: true },
    component: () => import('@/views/home'),
    hidden: true
  },
  {
    path: '/choujiang',
    meta: { title: '抽奖', noCache: true },
    component: () => import('@/views/lottery'),
    hidden: true
  },
  {
    path: '/guize',
    meta: { title: '规则', noCache: true },
    component: () => import('@/views/rule'),
    hidden: true
  },
  {
    path: '/tool',
    meta: { title: '工具', noCache: true },
    component: () => import('@/views/tool'),
    hidden: true
  },
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
